function caricaMappa() {
    if (document.getElementById('map')) {
        var Roma = new google.maps.LatLng(41.9028, 12.4964);
        var x = {
            zoom: 6, center: Roma, styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#a41d1d"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#1c7445"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "saturation": "-41"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "weight": "0.67"
                        },
                        {
                            "gamma": "0.42"
                        },
                        {
                            "lightness": "-37"
                        },
                        {
                            "color": "#137367"
                        },
                        {
                            "saturation": "55"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "lightness": "39"
                        },
                        {
                            "saturation": "37"
                        },
                        {
                            "gamma": "2.95"
                        },
                        {
                            "weight": "1.87"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "lightness": "65"
                        },
                        {
                            "gamma": "4.36"
                        },
                        {
                            "weight": "3.29"
                        },
                        {
                            "saturation": "-59"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#6d9786"
                        },
                        {
                            "lightness": "-5"
                        },
                        {
                            "saturation": "1"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "color": "#d2e6e4"
                        },
                        {
                            "saturation": "5"
                        },
                        {
                            "gamma": "0.53"
                        },
                        {
                            "weight": "0"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#ff0000"
                        },
                        {
                            "saturation": "4"
                        },
                        {
                            "lightness": "-71"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        }
        mappa = new google.maps.Map(document.getElementById('map'), x);
    }
}

function negozi() {
    var negozi = {
        province: [{
            "sigla": "AG",
            "provincia": "Agrigento"
        },
        {
            "sigla": "AL",
            "provincia": "Alessandria"
        },
        {
            "sigla": "AN",
            "provincia": "Ancona"
        },
        {
            "sigla": "AO",
            "provincia": "Aosta"
        },
        {
            "sigla": "AP",
            "provincia": "Ascoli Piceno"
        },
        {
            "sigla": "AQ",
            "provincia": "L'Aquila"
        },
        {
            "sigla": "AR",
            "provincia": "Arezzo"
        },
        {
            "sigla": "AT",
            "provincia": "Asti"
        },
        {
            "sigla": "AV",
            "provincia": "Avellino"
        },
        {
            "sigla": "BA",
            "provincia": "Bari"
        },
        {
            "sigla": "BG",
            "provincia": "Bergamo"
        },
        {
            "sigla": "BI",
            "provincia": "Biella"
        },
        {
            "sigla": "BL",
            "provincia": "Belluno"
        },
        {
            "sigla": "BN",
            "provincia": "Benevento"
        },
        {
            "sigla": "BO",
            "provincia": "Bologna"
        },
        {
            "sigla": "BR",
            "provincia": "Brindisi"
        },
        {
            "sigla": "BS",
            "provincia": "Brescia"
        },
        {
            "sigla": "BT",
            "provincia": "Barletta-Andria-Trani"
        },
        {
            "sigla": "BZ",
            "provincia": "Bolzano"
        },
        {
            "sigla": "CA",
            "provincia": "Cagliari"
        },
        {
            "sigla": "CB",
            "provincia": "Campobasso"
        },
        {
            "sigla": "CE",
            "provincia": "Caserta"
        },
        {
            "sigla": "CH",
            "provincia": "Chieti"
        },
        {
            "sigla": "CL",
            "provincia": "Caltanissetta"
        },
        {
            "sigla": "CN",
            "provincia": "Cuneo"
        },
        {
            "sigla": "CO",
            "provincia": "Como"
        },
        {
            "sigla": "CR",
            "provincia": "Cremona"
        },
        {
            "sigla": "CS",
            "provincia": "Cosenza"
        },
        {
            "sigla": "CT",
            "provincia": "Catania"
        },
        {
            "sigla": "CZ",
            "provincia": "Catanzaro"
        },
        {
            "sigla": "EN",
            "provincia": "Enna"
        },
        {
            "sigla": "FC",
            "provincia": "Forlì-Cesena"
        },
        {
            "sigla": "FE",
            "provincia": "Ferrara"
        },
        {
            "sigla": "FG",
            "provincia": "Foggia"
        },
        {
            "sigla": "FI",
            "provincia": "Firenze"
        },
        {
            "sigla": "FM",
            "provincia": "Fermo"
        },
        {
            "sigla": "FR",
            "provincia": "Frosinone"
        },
        {
            "sigla": "GE",
            "provincia": "Genova"
        },
        {
            "sigla": "GO",
            "provincia": "Gorizia"
        },
        {
            "sigla": "GR",
            "provincia": "Grosseto"
        },
        {
            "sigla": "IM",
            "provincia": "Imperia"
        },
        {
            "sigla": "IS",
            "provincia": "Isernia"
        },
        {
            "sigla": "KR",
            "provincia": "Crotone"
        },
        {
            "sigla": "LC",
            "provincia": "Lecco"
        },
        {
            "sigla": "LE",
            "provincia": "Lecce"
        },
        {
            "sigla": "LI",
            "provincia": "Livorno"
        },
        {
            "sigla": "LO",
            "provincia": "Lodi"
        },
        {
            "sigla": "LT",
            "provincia": "Latina"
        },
        {
            "sigla": "LU",
            "provincia": "Lucca"
        },
        {
            "sigla": "MB",
            "provincia": "Monza e della Brianza"
        },
        {
            "sigla": "MC",
            "provincia": "Macerata"
        },
        {
            "sigla": "ME",
            "provincia": "Messina"
        },
        {
            "sigla": "MI",
            "provincia": "Milano"
        },
        {
            "sigla": "MN",
            "provincia": "Mantova"
        },
        {
            "sigla": "MO",
            "provincia": "Modena"
        },
        {
            "sigla": "MS",
            "provincia": "Massa e Carrara"
        },
        {
            "sigla": "MT",
            "provincia": "Matera"
        },
        {
            "sigla": "NA",
            "provincia": "Napoli"
        },
        {
            "sigla": "NO",
            "provincia": "Novara"
        },
        {
            "sigla": "NU",
            "provincia": "Nuoro"
        },
        {
            "sigla": "OR",
            "provincia": "Oristano"
        },
        {
            "sigla": "PA",
            "provincia": "Palermo"
        },
        {
            "sigla": "PC",
            "provincia": "Piacenza"
        },
        {
            "sigla": "PD",
            "provincia": "Padova"
        },
        {
            "sigla": "PE",
            "provincia": "Pescara"
        },
        {
            "sigla": "PG",
            "provincia": "Perugia"
        },
        {
            "sigla": "PI",
            "provincia": "Pisa"
        },
        {
            "sigla": "PN",
            "provincia": "Pordenone"
        },
        {
            "sigla": "PO",
            "provincia": "Prato"
        },
        {
            "sigla": "PR",
            "provincia": "Parma"
        },
        {
            "sigla": "PT",
            "provincia": "Pistoia"
        },
        {
            "sigla": "PU",
            "provincia": "Pesaro e Urbino"
        },
        {
            "sigla": "PV",
            "provincia": "Pavia"
        },
        {
            "sigla": "PZ",
            "provincia": "Potenza"
        },
        {
            "sigla": "RA",
            "provincia": "Ravenna"
        },
        {
            "sigla": "RC",
            "provincia": "Reggio Calabria"
        },
        {
            "sigla": "RE",
            "provincia": "Reggio Emilia"
        },
        {
            "sigla": "RG",
            "provincia": "Ragusa"
        },
        {
            "sigla": "RI",
            "provincia": "Rieti"
        },
        {
            "sigla": "RM",
            "provincia": "Roma"
        },
        {
            "sigla": "RN",
            "provincia": "Rimini"
        },
        {
            "sigla": "RO",
            "provincia": "Rovigo"
        },
        {
            "sigla": "SA",
            "provincia": "Salerno"
        },
        {
            "sigla": "SI",
            "provincia": "Siena"
        },
        {
            "sigla": "SO",
            "provincia": "Sondrio"
        },
        {
            "sigla": "SP",
            "provincia": "La spezia"
        },
        {
            "sigla": "SR",
            "provincia": "Siracusa"
        },
        {
            "sigla": "SS",
            "provincia": "Sassari"
        },
        {
            "sigla": "SU",
            "provincia": "Sud Sardegna"
        },
        {
            "sigla": "SV",
            "provincia": "Savona"
        },
        {
            "sigla": "TA",
            "provincia": "Taranto"
        },
        {
            "sigla": "TE",
            "provincia": "Teramo"
        },
        {
            "sigla": "TN",
            "provincia": "Trento"
        },
        {
            "sigla": "TO",
            "provincia": "Torino"
        },
        {
            "sigla": "TP",
            "provincia": "Trapani"
        },
        {
            "sigla": "TR",
            "provincia": "Terni"
        },
        {
            "sigla": "TS",
            "provincia": "Trieste"
        },
        {
            "sigla": "TV",
            "provincia": "Treviso"
        },
        {
            "sigla": "UD",
            "provincia": "Udine"
        },
        {
            "sigla": "VA",
            "provincia": "Varese"
        },
        {
            "sigla": "VB",
            "provincia": "Verbano Cusio Ossola"
        },
        {
            "sigla": "VC",
            "provincia": "Vercelli"
        },
        {
            "sigla": "VE",
            "provincia": "Venezia"
        },
        {
            "sigla": "VI",
            "provincia": "Vicenza"
        },
        {
            "sigla": "VR",
            "provincia": "Verona"
        },
        {
            "sigla": "VT",
            "provincia": "Viterbo"
        },
        {
            "sigla": "VV",
            "provincia": "Vibo Valentia"
        }
        ],
        items: [], //all'inizio si potrebbe fare che centro ottico sia una chiamata asincrona con tutti i negozi
        url: "/api/shops?",
        negozio: "",
        citta: "",
        loading: false,
        provincia: "",
        accettabile: false,
        mostraNuvola: true,
        mostraFiltriMobile: false,
        interval: null,
        tutti: [],
        filtroProvince: [],
        timeout: null,
        richiesta: async function () {
            const response = await fetch(
                `${negozi.url}`
            );
            const data = await response.json();
            negozi.items = data;
            negozi.togliNulli();
            var selectProvincia = document.getElementById("provincia");
            negozi.items.forEach(element => {
                if (!negozi.filtroProvince.includes(element.province)) {
                    negozi.filtroProvince.push(element.province);
                }
            });

            negozi.province.forEach(element => {
                if (negozi.filtroProvince.includes(element.sigla)) {
                    var opzione = document.createElement('option');
                    opzione.value = element.sigla;
                    opzione.innerHTML = element.provincia;
                    selectProvincia.appendChild(opzione);
                }
            });

            negozi.tutti = negozi.items;
            negozi.visualizza();

            if(document.getElementById("citta")){
                var input = document.getElementById("citta");
                var options = {
                    types: ["geocode"],
                    componentRestrictions: { country: "IT" },
                    fields: ["address_components", "geometry", "icon", "name"]
                };
                var autocomplete = new google.maps.places.Autocomplete(input, options);
                var array = negozi.items;
                var citySearch = negozi.citySearch;

                autocomplete.addListener('place_changed', function evt(){
                    negozi.clearInput('citta');
                    var location = autocomplete.getPlace();
                    var lat = location.geometry.location.lat();
                    var lng = location.geometry.location.lng();
                    var result = citySearch(lat, lng, array).then(function(done) {
                        return done;
                    });
                    const printAddress = async () => {
                        const arrayItems = await result;
                        negozi.items = arrayItems;
                        negozi.items = negozi.items;
                        negozi.centra();
                        input.value = input.value + "";
                        input.dispatchEvent(new Event("input"));
                    };
                    printAddress();
                })
            }   
        },
        pari: function (item) {
            var indice = negozi.items.indexOf(item);
            if (indice % 2 == 0)
                return true;
            else
                return false;
        },
        checkEmpty: function () {
            // Riporta tutti i negozi se cancello "citta" che è un campo particolare
            if(negozi.citta.length == 0){
                negozi.items = negozi.tutti;
                negozi.centra();
            }
        },
        togliNulli: function () {
            negozi.items = negozi.items.filter(item => {
                if (item.lat != 0.000000 && item.lng != 0.000000) {
                    return item;
                }
            });
        },
        visualizza: function () {
            Markers = [];
            negozi.items.forEach(element => {
                var coordinate = new google.maps.LatLng(element.lat, element.lng);
                var infowindow = new google.maps.InfoWindow();

                var contents = negozi.caricaContenuti(element);
                var image = "/_Resources/Static/Packages/Proteinic.Greenvision/Images/pin.svg";
                var marker = new google.maps.Marker({
                    map: mappa,
                    position: coordinate,
                    title: element.name,
                    icon: image,
                });

                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent(contents);
                    infowindow.open(mappa, this);
                });
                Markers.push(marker);
                AllMarkers.push(marker);
            });
            MarkerCluster = new MarkerClusterer(mappa, Markers, {
                imagePath: "/_Resources/Static/Packages/Proteinic.Greenvision/Images/s",
            });
        },
        caricaContenuti: function (elemento) {
            var stringa = "<div class='contenuti'>"
            stringa += "<p class='info-negozio verde bold'>" + elemento.name + "</p>";
            stringa += "<p class='info-negozio info indirizzo-shop' >" + elemento.address.toLowerCase() + " - " + elemento.postcode + "</p>";
            stringa += "<p class='info-negozio info' >" + elemento.city + " - " + elemento.province + "</p>";
            if (elemento.sitoWeb) {
                stringa += "<div class='div-icona'>";
                stringa += "<img src=" + sito + "><a target='_blank' href='" + negozi.pulisciUrl(elemento.sitoWeb) + "' class='info-negozio verde info' >" + "<a target='_blank' href='" + negozi.pulisciUrl(elemento.sitoWeb) + "' class='info-negozio verde info'>" + negozi.pulisciUrl(elemento.sitoWeb) + "</a>";
                stringa += "</div>";
            }
            if (elemento.facebook) {
                stringa += "<div class='div-icona'>";
                stringa += "<img src=" + facebook + "><a target='_blank' href='" + negozi.pulisciUrl(elemento.facebook) + "' class='info-negozio verde info' >Facebook </a>";
                stringa += "</div>";
            }
            if (elemento.telephone) {
                stringa += "<div class='div-icona'>";
                stringa += "<img src=" + phone + "><a target='_blank' href='tel:" + elemento.telephone + "' class='info-negozio verde info' >" + "<a target='_blank' href='tel:" + elemento.telephone + "' class='info-negozio verde info' >T: " + elemento.telephone + "</a>";
                stringa += "</div>";
            }
            if (elemento.email) {
                stringa += "<div class='div-icona'>";
                stringa += "<img src=" + email + "><a target='_blank' href='mailto:" + elemento.email + "' class='info-negozio verde info' >" + "<a target='_blank' href='mailto:" + elemento.email + "' class='info-negozio verde info' >" + elemento.email + "</a>";
                stringa += "</div>";
            }
            if (elemento.whatsapp) {
                stringa += "<div class='div-icona'>";
                stringa += "<img src='" + whatsapp + "' ><a target='_blank' href='https://api.whatsapp.com/send?phone=" + elemento.whatsapp + "' class='info-negozio verde info' >  " + elemento.whatsapp + "</a>";
                stringa += "</div>";
            }
            stringa += "<div class='div-icona'>";
            stringa += "<img src=" + location_pin + "><a class='info-negozio verde info' target='_blank' href='https://maps.google.com?q=" + elemento.lat + "," + elemento.lng + "'> Apri in Google Maps</a>";
            stringa += "</div>";

            stringa += "</div>";
            return stringa;
        },
        mostraNegozio: function (item) {
            var pos = new google.maps.LatLng(item.lat, item.lng);
            mappa.setCenter(pos);
            mappa.setZoom(14);
        },
        displayMatches: function (type) {
            negozi.items = negozi.tutti;
            const matchArray = negozi.findMatches(type)
            if (negozi.accettabile) {
                negozi.items = matchArray;
                negozi.centra();
            }
        },
        citySearch: function (lat, lng, array) {
            var allItems = array;

            var promise = new Promise(async function(resolve, reject) {
                    
                var centerLat = lat;
                var centerLng = lng;

                var center = new google.maps.LatLng(centerLat, centerLng);
                
                var filteredItems = allItems.filter(element => {
                    var elementCoords = new google.maps.LatLng(element.lat, element.lng);
                    var distance = google.maps.geometry.spherical.computeDistanceBetween(center, elementCoords);
                    if(distance < 25000){
                        // 25000 vuol dire 25 Km (distance è in metri)
                        return element;
                    } 
                });
                resolve(filteredItems);
              });

            return promise;
        },
        findMatches: function (type) {
            // Cerca tra i campi dei negozi, funziona solo per le province e per i negozi
            var temp = negozi.items;
            switch (type) {
                case 'provincia':
                    if (negozi.provincia.length > 0) {
                        negozi.clearInput(type)
                        negozi.accettabile = true;
                        temp = temp.filter(item => {
                            const regex = new RegExp(negozi.provincia, 'i');
                            return item.province ? item.province.match(regex) : false
                        });
                    }
                    break;
                case 'negozio':
                    if (negozi.negozio.length >= 2) {
                        negozi.clearInput(type)
                        negozi.accettabile = true;
                        temp = temp.filter(item => {
                            const regex = new RegExp(negozi.negozio, 'i');
                            return item.name.match(regex)
                        });
                    }
                    break;
                default:
                    break;
            }

            return temp;
        },
        centra: function () {
            negozi.deleteMarkers();
            var tmp = negozi.items;

            if (tmp.length == 0) {
                var Roma = new google.maps.LatLng(41.9028, 12.4964);
                mappa.setCenter(Roma);
                mappa.setZoom(6);
                document.getElementById('messaggio-vuoto').style.display = "block";
                return;
            }
            document.getElementById('messaggio-vuoto').style.display = "none";
            var latlngbounds = new google.maps.LatLngBounds();
            tmp.filter(item => {
                var latlng = new google.maps.LatLng(item.lat, item.lng);
                latlngbounds.extend(latlng);
            });
            negozi.visualizza();
            mappa.fitBounds(latlngbounds);
        },
        pulisciUrl: function (sito) {
            if (!/^(?:f|ht)tps?\:\/\//.test(sito)) {
                sito = "http://" + sito;
            }
            return sito;
        },
        cambiaNuvola: function () {
            negozi.mostraNuvola = !negozi.mostraNuvola;
        },
        deleteMarkers() {
            MarkerCluster.setMap(null);
            negozi.clearMarkers();
        },
        setMapOnAll(mappa) {
            for (var i = 0; i < Markers.length; i++) {
                Markers[i].setMap(mappa);
            }
        },
        clearMarkers() {
            negozi.setMapOnAll(null);
        },
        showMarkers() {
            negozi.setMapOnAll(map);
        },
        aggiungiMarkers() {
            negozi.setMapOnAll(mappa);

            MarkerCluster = new MarkerClusterer(mappa, Markers, {
                imagePath: "/_Resources/Static/Packages/Proteinic.Greenvision/Images/s",
            });
        },
        clearInput(param) {
            // Se inserisco un campo cancello gli altri, in questo modo evito confusione
            switch (param) {
                case 'citta':
                    negozi.provincia = "Provincia";
                    negozi.negozio = "";
                    // Fallback visto che alcune volte non lo resetta
                    document.getElementById('negozio').value = "";
                    document.getElementById('provincia').getElementsByTagName('option')[0].selected = 'selected'
                    break;
                case 'provincia':
                    negozi.citta = "";
                    negozi.negozio = "";
                    break;
                case 'negozio':
                    negozi.provincia = "Provincia";
                    negozi.citta = "";
                    break;
                default:
                    break;
            }
        }
    }
    return negozi;
}

export { negozi, caricaMappa }

